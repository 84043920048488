<template>
  <div class="examination-record">
    <div class="top">
      练习记录
      <div class="go-back" @click="goBack">
        返回我的题库
      </div>
    </div>
    <div v-loading="loading" :element-loading-text="'加载中'" class="list-content">
      <div class="tabs">
        <div v-for="(item, index) in questionName" :key="index">
          <span class="tab-item" :class="index === activeNames ? 'active' : ''" @click="changeTabs(index, item.type)">
            {{ item.name }}
          </span>
          <span v-if="index !== 2" class="line" />
        </div>
      </div>
      <template v-if="recordList.length > 0">
        <div v-for="(item, index) in recordList" :key="index" class="list-wrap">
          <div class="date-tit">
            <img src="@/assets/img/Home/question/date.png">
            {{ item.date | format("M月d日") }}
          </div>
          <div v-for="(record, i) in item.recordVoList" :key="i" class="record-list" @click="toRecordDetail(record)">
            <div>
              <div class="course-name ellipsis">{{ record.kcName }}</div>
              <div class="answer-times">
                <i class="iconfont icon-daishouhuo" />
                用时：{{ formatAnswerTime(record.answerTimes) }}
              </div>
            </div>
            <div class="correct-rate">
              <img src="@/assets/img/Home/question/shu.png" alt="">
              <span>正确率{{ record.correctRate }}</span>
            </div>
          </div>
        </div>
      </template>
      <div v-if="!recordList || recordList.length == 0" class="nothing">
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination class="pagination" :total="total" :current-page="search.pageNum" :page-sizes="[8, 16, 25, 40]"
        :page-size="search.pageSize" :pager-count="5" background layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
  </div>
</template>

<script>
import { getPracticeRecordList } from '@/api/question'
export default {
  data() {
    return {
      activeNames: 0,
      questionName: [
        { name: '客观题', type: 1 },
        { name: '易错题', type: 3 },
        { name: '错题库', type: 2 }
      ],
      search: {
        type: 1,
        pageSize: 8,
        pageNum: 1
      },
      total: 0,
      loading: false,
      recordList: []
    }
  },
  created() {
    this.getRecordList()
  },
  methods: {
    changeTabs(val, type) {
      this.activeNames = val
      this.search.type = type
      this.search.pageNum = 1
      this.recordList = []
      this.total = 0
      this.getRecordList()
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.getRecordList()
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getRecordList()
    },
    async getRecordList() {
      this.loading = true
      const { total, rows } = await getPracticeRecordList(this.search)
      this.total = total
      this.recordList = rows
      this.loading = false
    },
    // 返回我的题库
    goBack() {
      this.$router.go(-1)
    },
    // 跳转记录详情
    toRecordDetail(record) {
      this.$router.push(`/quesTion/examTest?kId=${record.kid}&kcName=${record.kcName}&type=${this.activeNames}&isSubmit=true&recordId=${record.id}&answerTimes=${record.answerTimes}&isRecord=${true}`)
    }
  }
}
</script>

<style lang="less" scoped>
.examination-record {
  .top {
    width: 880px;
    height: 64px;
    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
    font-size: 18px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #5A5D5A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 0 24px;

    .go-back {
      width: 102px;
      height: 25px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #FF5E51;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FF5E51;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .list-content {
    width: 880px;
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding: 16px 40px 20px 24px;

    .tabs {
      display: flex;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #5A5D5A;
      margin-bottom: 15px;

      .tab-item {
        cursor: pointer;
      }

      .active {
        color: #FF5E51;
      }

      .line {
        display: inline-block;
        height: 14px;
        width: 1px;
        background: #DCDFE6;
        margin: 0 10px;
      }
    }

    .list-wrap {
      padding-top: 5px;

      .date-tit {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;

        >img {
          width: 19px;
          height: 19px;
          margin-right: 7px;
        }
      }

      // >:last-child {
      //   border: none !important;
      // }
      .record-list {
        padding: 14px 0 0;
        border-bottom: #F5F5F5 1px solid;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .course-name {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          width: 618px;
        }

        .answer-times {
          margin-top: 10px;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          >.iconfont {
            margin-right: 3px;
            font-size: 14px;
          }
        }

        .correct-rate {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #FF5E51;
          display: flex;
          align-items: center;

          >img {
            width: 13px;
            height: 12px;
            margin-right: 3px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
</style>
